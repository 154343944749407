.title {
  font-size: 32px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.96px;
  font-family: var(--urbanist);
  text-align: center;
  text-transform: none;
}

.row {
  display: flex;
  gap: 24px;
}

.scenario {
  outline: 2px solid var(--coral);
  border: 16px solid #fff;
  background: var(--gray200);
  padding: 32px;
  margin-top: 32px;
  flex: 1 0 0;
}

.scenarioTitle {
  color: var(--coral);
  font-size: 22px;
  font-weight: 800;
  line-height: 120%;
}

.scenarioSubtitle {
  font-size: 14px;
  line-height: 20px;
  margin: 4px 0 16px;
}

.description {
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}

.seconds {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-top: 8px;
  width: 300px;
  border-top: 1px solid var(--coral);
  margin: 28px auto 0;
}

.icon {
  color: var(--coral);
  font-size: 24px;
}

.secondsText {
  font-family: var(--avenir);
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

@media (max-width: 900px) {
  .wrapper {
    margin-top: 64px;
  }

  .title {
    margin-bottom: 32px;
  }

  .row {
    flex-direction: column;
  }

  .scenario {
    margin-top: 0;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 28px;
    font-weight: 800;
    line-height: 120%;
    letter-spacing: normal;
  }

  .scenario {
    border: 21px solid #fff;
    padding: 16px;
  }

  .seconds {
    width: 100%;
  }
}

.wrapper {
  height: 100%;
  position: relative;
  max-height: 100vh;
}

.videowrap {
  max-width: 100%;
  height: 100%;
  width: 100vw;

  :global(.vjs-poster) {
    background-color: #000;
    img {
      display: none;
    }
  }
}

.close {
  position: fixed;
  top: 24px;
  right: 24px;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

.languageSelector {
  background-color: transparent;
  p,
  div,
  span,
  i {
    color: #fff;
  }
}

@media (max-width: 600px) {
  .languageSelector p {
    color: rgba(255, 255, 255, 0.6);
  }
  .wrapper {
    height: fit-content;
  }
}

@media (max-height: 768px) and (min-width: 600px) {
  .wrapper {
    max-height: 100vh;
    height: 100%;
    width: auto;
  }

  .videowrap {
    width: auto;
    height: 100vh;
    aspect-ratio: 16/9;
    max-width: calc(100vw - 64px);
  }

  .close {
    display: none;
  }

  .languageSelector {
    position: absolute;
    background-color: var(--dark60);
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}

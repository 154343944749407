.arrowIcon {
  width: 31px;
  height: 28px;
}

.hiw {
  background-color: #fff;
  height: 100%;
  padding: 0;
}

.hiwContentWrapper {
  padding: 0 48px;
  margin-bottom: 80px;
}

.hiwTitle {
  font-family: var(--urbanist);
  font-size: 32px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.96px;
  text-align: center;
  padding-top: 64px;
  text-transform: none;
  margin-bottom: 32px;
}

.hiwImageWrapper {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.hiwImage {
  flex-grow: 3;
  height: auto;
  width: 100%;
  cursor: pointer;
}

.listWrapper {
  flex-grow: 4;
  display: flex;
  align-items: center;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.listItem {
  display: flex;
  gap: 16px;
  list-style: none;
  align-items: flex-start;
}

.listTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
}

.listText {
  margin-top: 2px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  white-space: pre-wrap;
}

.numWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 53px;
  height: 53px;
  border-radius: 50%;
  border: 1px solid #c9c9c9;
  flex-shrink: 0;
}

.numText {
  color: var(--coral);
  font-family: var(--urbanist);
  font-size: 28px;
  font-weight: 900;
  line-height: 120%;
  letter-spacing: 0.869px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  border-radius: 50%;
  border: 1px solid var(--dark60);
}

.watchBtn button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--coral);
  border: none;
  width: 270px;

  .icon {
    font-size: 20px;
    color: #fff;
  }
}

.popup {
  :global(.popupContent) {
    max-width: 1200px;
  }

  :global(.popupContentWrap) {
    background: transparent !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;
    max-height: unset !important;
  }

  :global(.popupHeader) {
    display: none;
  }

  :global(.popupBackground) {
    opacity: 0.7;
    background-color: var(--dark100);
  }
}

@media (max-width: 900px), (max-height: 570px) {
  .hiwContainer {
    padding: 0 24px !important;
  }

  .hiw {
    transform: translateY(0);
    animation: unset !important;
    height: auto;
  }

  .hiwContentWrapper {
    padding: 0;
    margin-bottom: 0;
  }

  .hiwImageWrapper {
    max-width: 420px;
  }

  .listWrapper {
    flex-direction: column;
    width: 100%;
    margin-top: 32px;
  }

  .hiwImage {
    width: 100%;
  }

  .list {
    margin-top: 32px;
    width: 100%;
    padding-left: 0;
  }
}

@media (max-width: 600px) {
  .numWrapper {
    width: 40px;
    height: 40px;
  }

  .hiwTitle {
    letter-spacing: normal;
    margin-bottom: 28px;
  }

  .numText {
    width: 29px;
    height: 29px;
    font-size: 20px;
  }

  .list {
    margin-top: 16px;
    gap: 24px;
  }

  .listWrapper {
    margin-top: 16px;
    gap: 24px;
  }

  .popup {
    :global(.popupContent) {
      max-width: 100%;
    }

    :global(.popupBackground) {
      opacity: 1 !important;
      background-color: var(--dark100);
    }
    :global(.popupContentWrap) {
      aspect-ratio: 16/9;
      width: 100vw !important;
      max-width: 100% !important;
      display: flex;
      align-items: center;
      height: 100% !important;
    }
  }
}

@media (max-height: 840px) {
  .popup {
    :global(.popupBackground) {
      opacity: 0.9;
      background-color: var(--dark100);
    }

    :global(.popupContent) {
      max-width: 1000px;
    }
  }
}

@media (max-height: 768px) and (min-width: 600px) {
  .popup {
    :global(.popupContent) {
      max-width: 100%;
    }

    :global(.popupBackground) {
      opacity: 1;
      background-color: var(--dark100);
    }

    :global(.popupContentWrap) {
      aspect-ratio: 16/9;
      width: 100vw !important;
      max-width: calc(100% - 64px) !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

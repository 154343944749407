.videojsWrapper {
  :global(.vjs-iframe-blocker) {
    display: block;
  }
  :global(.video-js) {
    iframe {
      pointer-events: none;
    }
    :global(.vjs-control-bar) {
      background: var(--dark60);
    }
    :global(.vjs-load-progress) div {
      background: #fff;
      border-radius: 200px;
    }
    :global(.vjs-play-progress) {
      background: var(--coral);
      border-radius: 200px;

      &::before {
        color: var(--coral);
      }
    }
    :global(.vjs-progress-holder) {
      background: #d9d9d9;
      border-radius: 200px;
    }
    :global(.vjs-remaining-time-display) {
      font-family: var(--roboto);
      font-size: 14px;
      font-weight: 400;
    }
    :global(.vjs-fullscreen-control .vjs-icon-placeholder)::before {
      content: '\e948';
    }
    :global(.vjs-play-control .vjs-icon-placeholder)::before {
      content: '\e94d';
    }
    :global(.vjs-icon-placeholder) {
      font-family: var(--icons) !important;
      &::before {
        font-size: 22px;
        line-height: 30px;
      }
    }
    :global(.vjs-vol-0 .vjs-icon-placeholder)::before {
      content: '\e947';
    }
    :global(.vjs-vol-1 .vjs-icon-placeholder)::before {
      content: '\e94b';
    }
    :global(.vjs-vol-2 .vjs-icon-placeholder)::before {
      content: '\e949';
    }
    :global(.vjs-vol-3 .vjs-icon-placeholder)::before {
      content: '\e94c';
    }
    :global(.vjs-paused .vjs-icon-placeholder)::before {
      content: '\e94a';
    }
    :global(.vjs-ended .vjs-icon-placeholder)::before {
      content: '\e94e';
    }
    :global(.vjs-remaining-time) {
      padding: 0;
    }
    :global(.vjs-big-play-button) {
      background: var(--dark60);
      border-radius: 50%;
      width: 64px;
      height: 64px;
      content: '\e94a';
      border: none;
      margin-top: -32px;
      margin-left: -32px;
      transition: transform 0.25s ease, opacity 0.15s ease;

      :global(.vjs-icon-placeholder) {
        &::before {
          font-size: 32px;
          line-height: 64px;
        }
      }
    }

    :global(.vjs-volume-tooltip),
    :global(.vjs-time-tooltip) {
      font-family: var(--roboto);
      font-size: 14px;
      font-weight: 400;
    }

    :global(.vjs-volume-level) {
      background-color: var(--coral);
    }
    :global(.vjs-volume-level)::before {
      color: var(--coral);
    }
  }

  :global(.vjs-waiting .vjs-big-play-button),
  :global(.vjs-seeking .vjs-big-play-button) {
    display: none;
  }
  :global(.vjs-loading-spinner),
  :global(.vjs-loading-spinner)::after,
  :global(.vjs-loading-spinner)::before {
    width: 64px;
    height: 64px;
  }

  :global(.vjs-loading-spinner)::before {
    border-top-color: var(--coral) !important;
  }
  :global(.vjs-loading-spinner)::after {
    display: none;
  }
  :global(.vjs-loading-spinner) {
    border-color: var(--dark60);
    border-width: 7px;
  }

  @media (max-width: 600px) {
    :global(.vjs-play-control),
    :global(.vjs-volume-control) {
      display: none;
    }

    :global(.vjs-playing) {
      :global(.vjs-big-play-button) {
        display: block;
        transform: scale(0.3);
        opacity: 0;
      }
    }

    :global(.vjs-paused:not(.vjs-waiting)) {
      :global(.vjs-big-play-button) {
        display: block;
        transform: scale(1);
        opacity: 1;
      }
    }
    :global(.vjs-playing) {
      :global(.vjs-icon-placeholder)::before {
        content: '\e94d';
        font-family: var(--icons);
      }
    }
    :global(.vjs-user-active:not(.vjs-waiting):not(.vjs-paused)) {
      :global(.vjs-big-play-button) {
        display: block;
        transform: scale(1);
        opacity: 1;
      }
    }
    :global(.vjs-fullscreen) {
      display: flex !important;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      > div {
        width: 100%;
      }

      :global(.vjs-big-play-button) {
        position: absolute;
      }

      :global(.vjs-text-track-display) {
        display: none;
      }

      :global(.vjs-control-bar) {
        position: relative;
      }

      :global(.vjs-tech) {
        position: relative !important;
        width: 100%;
        height: auto;
        aspect-ratio: 16/9;
      }
    }
  }
}
